import React, { useEffect, useMemo } from 'react';
import { useController } from '../../../hooks/use-controller';
import { GlobalSearchController } from '../global-search-controller';
import { initialGlobalSearchResults } from '../models/global-search';
import { GlobalSearchView } from './GlobalSearchView';

export function GlobalSearch({ sidebarCollapsed }: { sidebarCollapsed: boolean }) {
  // yuck
  const initialStateGenerator = useMemo(() => () => initialGlobalSearchResults(sidebarCollapsed), [sidebarCollapsed]);

  const { model, delegate } = useController(initialStateGenerator, GlobalSearchController);
  useEffect(() => {
    delegate?.onSidebarCollapsedToggle(sidebarCollapsed);
  }, [delegate, sidebarCollapsed]);

  return (delegate && <GlobalSearchView model={model} delegate={delegate} />) ?? null;
}
