/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from 'axios';
import { LinkedError } from './linked-error';
import util from 'util';
import { Logger, LoggerOptions } from 'pino';

export type MstResponse<T = object> = (T & { ok: true }) | { ok: false; error: string };

export type MstGenericResponse = MstResponse<void>;

export type MstDataResponse<T = object> = MstResponse<{ data: T }>;

let logger: Logger<LoggerOptions> | Console | undefined = undefined;

/**
 * @returns If it's called from a Node project, pino logger. Otherwise, browser console.
 */
async function getLogger() {
  if (logger) {
    return logger;
  }

  try {
    const loggingModule = await import('../logging');
    logger = loggingModule.getLogger({ file: __filename });
  } catch (err) {
    logger = console;
  }

  return logger;
}

/**
 * Removes circular references (especially needed in Jest tests: https://github.com/jestjs/jest/issues/10577) and unnecessary fields
 */
function cleanError(error: object) {
  // axios requests can log the request headers and data:
  // as those can contain sensitive (or very large) data, we omit them
  if ('config' in error) {
    const cleanedConfig: object = { ...(error.config as object) };
    if ('data' in cleanedConfig) {
      cleanedConfig.data = '<omitted>';
    }
    if ('headers' in cleanedConfig) {
      cleanedConfig.headers = '<omitted>';
    }
    error.config = cleanedConfig;

    // omit any token-related values
    if ('params' in cleanedConfig && typeof cleanedConfig.params === 'object') {
      const cleanedParams = { ...cleanedConfig.params };
      for (const key in cleanedParams) {
        if (key.toLowerCase().includes('token')) {
          (cleanedParams as { [k: string]: unknown })[key] = '<omitted>'; // token-related values omitted
        }
      }
      cleanedConfig.params = cleanedParams;
    }

    // keep only white-listed fields from config
    const whitelistConfigFields: Array<string> = ['method', 'url', 'params'];
    const finalConfig: { [k: string]: unknown } = {};
    whitelistConfigFields.forEach((field) => {
      if (field in cleanedConfig) {
        finalConfig[field] = (cleanedConfig as { [k: string]: unknown })[field];
      }
    });

    error.config = finalConfig;
  }

  if ('request' in error) {
    const { _options, _ended, _ending, _redirectCount, _currentUrl } = error.request as { [k: string]: any };
    if (_options && 'nativeProtocols' in _options) {
      _options.nativeProtocols = undefined; // useless info
    }
    error.request = { _options, _ended, _ending, _redirectCount, _currentUrl };
  }

  if ('response' in error) {
    const response = error.response as { [k: string]: unknown };
    const { request, config } = response;
    // remove request and config
    if (request && 'request' in response) {
      error.response = { ...(error.response as object), request: '<omitted>' };
    }
    if (config && 'config' in response) {
      error.response = { ...(error.response as object), config: '<omitted>' };
    }
  }

  try {
    JSON.stringify(error);
  } catch (err: unknown) {
    // if we didn't catch a circular reference, serialize the entire object
    return util.inspect(error, false, 4);
  }

  return error;
}

/*
  Note: with the following error handling improvement introduced in axios v1.0
  we might be able to simplify or remove these functions:
  https://github.com/axios/axios/pull/4624
*/

export async function handleAxiosError<T>(
  response: Promise<AxiosResponse<T>>,
  options?: { silent?: boolean }
): Promise<T | LinkedError<{ responseStatus?: number; responseData?: unknown }>> {
  const logger = await getLogger();

  const responseOrError = await response.catch((err: Error) => err);

  if (responseOrError instanceof Error) {
    const error = cleanError({ ...responseOrError });
    const linkedError = new LinkedError('request error', undefined, error as any);

    if (!options?.silent) {
      logger.debug(linkedError);
    }

    return linkedError;
  } else if (responseOrError.status < 200 || responseOrError.status > 299) {
    const linkedError = new LinkedError('request status error', undefined, {
      responseStatus: responseOrError.status,
      responseData: responseOrError.data,
    });

    if (!options?.silent) {
      logger.debug(
        {
          err: linkedError,
          responseStatus: responseOrError.status,
          url: responseOrError.config.url,
          method: responseOrError.config.method,
          responseData: responseOrError.data,
        },
        'request failed'
      );
    }

    return linkedError;
  } else {
    return responseOrError.data;
  }
}

/**
 * Given an axios response, throws an error in case the status is not in 200-299 range.
 * The error preserves stack information and deletes the headers & posted data.
 *
 * Assumes that response was created with the { validateStatus: null } option set.
 *
 * @param response the axios response.
 * @returns the response data.
 */
export async function throwAxiosError<T>(response: Promise<AxiosResponse<T>>): Promise<T> {
  const responseOrError = await handleAxiosError(response);
  if (responseOrError instanceof LinkedError) {
    throw responseOrError;
  }

  return responseOrError;
}

/**
 * Given an axios response, logs an error in case the status is not in 200-299, and returns undefined.
 * The logged error preserves stack information and deletes the headers & posted data.
 *
 * Assumes that response was created with the { validateStatus: null } option set.
 *
 * @param response the axios response.
 * @param errorMessage the message to log along with the exception.
 * @returns the response data, or undefined in case of error.
 */
export async function checkAxiosResponse<T>(
  response: Promise<AxiosResponse<T>>,
  errorMessage = 'request failed',
  options?: { silent?: boolean }
): Promise<T | undefined> {
  const logger = await getLogger();

  const responseOrError = await handleAxiosError(response, options);
  if (responseOrError instanceof LinkedError) {
    if (!options?.silent) {
      logger.error(responseOrError, errorMessage);
    }
    return undefined;
  }

  return responseOrError;
}

export async function extractValidationError<T>(response: Promise<AxiosResponse<T>>): Promise<MstResponse<T> | undefined> {
  const responseOrError = await handleAxiosError(response);

  if (responseOrError instanceof LinkedError && responseOrError.cause.responseStatus === 400) {
    return { ok: false, error: (responseOrError.cause.responseData as { error?: string })?.error ?? '' };
  }

  if (responseOrError instanceof LinkedError) {
    const logger = await getLogger();
    logger.error(responseOrError);
    return undefined;
  }

  return { ...responseOrError, ok: true };
}
