import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Alert, Button, Modal, Select, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { CheckCircleOutlined, ExclamationCircleTwoTone, InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons/lib/icons';
import { formatDateTime } from '../../../services/formatting';
import { PaddedContainer } from '../../../_shared/PaddedContainer';
import PageHeader from '../../../_shared/PageHeader';
import { Toolbar } from '../../../_shared/Toolbar';
import { createNumericSorter, getOnFilterHandler, getTableColumnFilters } from '../../../utils/tables';
import { InfoDialog } from '../../jobs/views/InfoDialog';
import { DataLakeMonitorStore } from '../models/data-lake-monitor-store';

import styles from './DataLakeStatusMonitorView.module.css';

const { Text } = Typography;

const ALERT_COLOR = '#eb2f96';

export interface DataLakeMonitorViewDelegate {
  dispose: () => void;
  selectTables: (tables: string[]) => void;
  reloadDataLakeStatus: () => Promise<void>;
  runRefresh: () => Promise<void>;
}

export function DataLakeStatusMonitorView({ model, delegate }: { model: DataLakeMonitorStore; delegate: DataLakeMonitorViewDelegate }) {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [moreInfoDialogVisible, setMoreInfoDialogVisible] = useState<Record<string, string | boolean> | undefined>();

  const statusData = model.data?.status || [];
  const selectedTables = model.selectedTables;
  const serverRunning = model.running;

  // we generate this in the view, as our model should not have any decision about how it
  // is displayed
  const data = useMemo(() => {
    const dates: string[] = [];
    const values: Record<string, Record<string, string | number>> = {}; // { 'bats_edga': { f: 'bats_edga', '2024-12-05': 2, ... } }

    for (const { dt, f, table } of statusData) {
      if (!dates.includes(dt)) {
        dates.push(dt);
      }

      const c = selectedTables?.includes(table) ? 1 : 0;

      const feed = values[f] || (values[f] = { f });
      if (feed[dt]) {
        (feed[dt] as number) += c;
      } else {
        feed[dt] = c;
      }
    }

    dates.sort();

    const rows = Object.values(values);

    const columns: ColumnType<Record<string, string | number>>[] = [
      {
        title: 'Feed',
        dataIndex: 'f',
        sorter: (lhs: Record<string, string | number>, rhs: Record<string, string | number>) =>
          lhs['f'].toString().localeCompare(rhs['f'].toString()),
        filterSearch: true,
        filters: getTableColumnFilters(rows, 'f'),
        onFilter: getOnFilterHandler('f'),
      },
      ...dates.map((d) => {
        const day = dayjs(d);

        return {
          title: <span>Σ&nbsp;{day.format('ddd DD-MMM-YY')}</span>,
          dataIndex: d,
          width: '180px',
          sorter: createNumericSorter(d),
          render: (value: number | string, row) => {
            const now = value || 0;
            const key = `${d}|${row.f}`;

            const icon = now === 0 ? <ExclamationCircleTwoTone twoToneColor={ALERT_COLOR} /> : <CheckCircleOutlined />;
            const colour = now === 0 ? { color: ALERT_COLOR } : undefined;

            return (
              <div className={styles.innerCell}>
                {icon}
                <div className={styles.cell} style={colour}>
                  {now}
                </div>
                <Button size={'small'} type={'text'} shape={'circle'} data-location={key} onClick={handleMoreInfoClick}>
                  ...
                </Button>
              </div>
            );
          },
        } as ColumnType<Record<string, string | number>>;
      }),
    ];

    return { values, dates, rows, columns };
  }, [model]);

  const handleMoreInfoClick = useCallback(
    (a: MouseEvent) => {
      const attribute = a.currentTarget.getAttribute('data-location') as string | undefined;
      if (!attribute) {
        return;
      }

      const [date, feed] = attribute.split('|');
      const record = statusData.reduce(
        (acc, cur) => {
          if (cur.dt !== date || cur.f !== feed) {
            return acc;
          }

          return { ...acc, [cur.table]: true };
        },
        {
          _feed: feed,
          _date: date,
        }
      );

      setMoreInfoDialogVisible(record);
    },
    [statusData]
  );

  const handleChange = useCallback(
    (value: string[]) => {
      delegate.selectTables(value);
    },
    [delegate]
  );

  const handleCancelClick = useCallback(() => {
    setMoreInfoDialogVisible(undefined);
  }, [setMoreInfoDialogVisible]);

  const handleRefreshClick = useCallback(
    (e: { shiftKey: boolean }) => {
      const forceRefresh = e.shiftKey === true;
      if (!forceRefresh) {
        void delegate.runRefresh();
        return;
      }

      void delegate.reloadDataLakeStatus();
    },
    [delegate]
  );

  return (
    <PaddedContainer>
      <PageHeader
        headerText={
          <div className="pageTitleWithInfoIcon">
            Data Lake Monitor
            <Tooltip title={'Status table further information'} placement="top">
              <Button
                icon={<InfoCircleOutlined style={{ marginLeft: '5px' }} />}
                onClick={() => setDialogVisible(true)}
                className="infoIcon"
                data-testid="show-page-info"
              />
            </Tooltip>
          </div>
        }
      />

      <Toolbar noForm>
        <label htmlFor="select-item">Visible Tables:</label>
        <Select
          className={'grow'}
          style={{ width: '400px', minWidth: '400px' }}
          options={(model.tables || []).map((table) => ({ value: table, label: <span>{table}</span> }))}
          mode="multiple"
          placeholder="Select Item..."
          maxTagCount="responsive"
          disabled={model.state === 'loading'}
          value={model.selectedTables}
          onChange={handleChange}
          id={'select-item'}
          allowClear
        />
        <Alert
          className={styles.alert}
          message={
            serverRunning
              ? 'Querying Data Lake...'
              : `Last Checked: ${model.data?.syncDate ? formatDateTime(model.data['syncDate']) : 'unknown'}.`
          }
          type="info"
        />
        <Button
          title="Immediately refresh data from server"
          icon={<ReloadOutlined spin={serverRunning || model.state === 'loading'} />}
          onClick={handleRefreshClick}
          type={'text'}
        />
      </Toolbar>

      <Space direction="vertical" style={{ width: '100%' }}>
        <Table
          loading={model.state === 'loading'}
          bordered
          dataSource={data.rows}
          rowKey={'f'}
          columns={data.columns}
          pagination={{
            position: ['bottomLeft'],
            defaultPageSize: 10,
            total: data.rows?.length,
            hideOnSinglePage: true,
            showSizeChanger: true,
          }}
        ></Table>
      </Space>

      {moreInfoDialogVisible && (
        <Modal
          title={'Table/Feed/Date Information'}
          cancelButtonProps={{ style: { display: 'none' } }}
          loading={false}
          onOk={handleCancelClick}
          onCancel={handleCancelClick}
          open={moreInfoDialogVisible !== undefined}
        >
          <>
            <div className={styles.infoCell}>
              <strong>Feed:&nbsp;&nbsp;</strong>
              {moreInfoDialogVisible['_feed']}
            </div>
            <div className={styles.infoCell}>
              <strong>Date:&nbsp;&nbsp;</strong>
              {moreInfoDialogVisible['_date']}
            </div>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Table</th>
                  <th>Has data</th>
                </tr>
              </thead>
              <tbody>
                {selectedTables?.sort().map((table) => {
                  const hasData = moreInfoDialogVisible[table] || false;
                  return (
                    <tr key={table}>
                      <td>{table}</td>
                      <td className={styles.codeCell}>
                        {hasData ? <CheckCircleOutlined /> : <ExclamationCircleTwoTone twoToneColor={ALERT_COLOR} />}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        </Modal>
      )}

      {dialogVisible && (
        <InfoDialog
          model={{
            title: 'Data Lake Status Information',
            isNoDocs: true,
            content: (
              <Space direction="vertical">
                <Text>This page shows the status of data in each table inside the Data Lake for each feed.</Text>
                <Text>
                  A process runs every few hours and queries the Data Lake for the presence of data for every &apos;table&apos;,
                  &apos;f&apos; and &apos;dt&apos; combination.
                </Text>
                <Text>
                  Querying the Data Lake for this information is computationally expensive, hence the row count could be a few hours old;
                  please check the &apos;Last Checked&apos; value in the toolbar to see when the Lake was last successfully checked.
                </Text>
                <Text className={styles.disclaimer}>
                  Please note that visibility of a feed here does not convey access to said feed; please review your agreement with
                  LSEG/Refinitiv for further information.
                </Text>
              </Space>
            ),
          }}
          delegate={{ closeDialog: () => setDialogVisible(false) }}
        />
      )}
    </PaddedContainer>
  );
}
