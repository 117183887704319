import { useCallback, useContext, useMemo, useState } from 'react';

import { KeyOutlined, LogoutOutlined, MailOutlined, QuestionCircleOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, MenuProps, Popover } from 'antd';

import { ArrowLeftIcon, ArrowRightIcon, MoonIcon, SunIcon, UserIcon } from '@heroicons/react/24/outline';

import { ItemType } from 'antd/lib/menu/interface';

import { EmptyBellIconComponent } from '../../../_shared/IconControls';

import { AppThemeContext } from '../../../hooks/use-theme';

import { NotificationsListModel } from '../../../side-nav/controls/notifications/models/notifications-list';
import {
  NotificationListControlDelegate,
  NotificationsListControl,
} from '../../../side-nav/controls/notifications/views/NotificationsListControl';

import styles from './UserInfo.module.css';

import {
  ABOUT_MENU_ITEM_KEY,
  ADD_GITLAB_API_KEY,
  API_GEN_ITEM_KEY,
  EMAIL_ITEM_KEY,
  LOGOUT_ITEM_KEY,
  SUPPORT_MENU_ITEM_KEY,
  USER_PREFERENCES_KEY,
  UserInfoModel,
  USERNAME_ITEM_KEY,
} from '../models/user-info';
import { AboutDialog } from './AboutDialog';
import { AddGitlabApiKeyDelegate } from './AddGitlabApiKeyDialog';
import { GenerateApiKeyDelegate } from './GenerateApiKeyDialog';
import { UserPreferencesDelegate } from './UserPreferencesDialog';

export interface UserInfoDelegate extends GenerateApiKeyDelegate, AddGitlabApiKeyDelegate, UserPreferencesDelegate {}

export function UserInfo({
  model,
  delegate,
  notificationsModel,
  notificationsDelegate,
  signOut,
  onSidebarCollapsed,
}: {
  model: UserInfoModel;
  delegate: UserInfoDelegate;
  notificationsModel: NotificationsListModel;
  notificationsDelegate: NotificationListControlDelegate | undefined;
  signOut: () => void;
  onSidebarCollapsed?: (collapsed: boolean) => void;
}) {
  const { theme, toggleTheme } = useContext(AppThemeContext);

  const [aboutDialogOpen, setAboutDialogOpen] = useState(false);

  const { user, sidebarCollapsed } = model;

  const handleSidebarCollapseToggle = useCallback(() => onSidebarCollapsed?.(!sidebarCollapsed), [sidebarCollapsed, onSidebarCollapsed]);

  const handleAboutDialogClose = useCallback(() => {
    setAboutDialogOpen(false);
  }, [setAboutDialogOpen]);

  const createSupportMail = useCallback(() => {
    const {
      supportEmailAddress,
      branding: { shortName },
    } = window.CONFIG;
    window.open(`mailto:${supportEmailAddress}?subject=${shortName}%20Support%20Question`);
  }, []);

  const handleDropDownMenuItemClick: MenuProps['onClick'] = (menuInfo) => {
    switch (menuInfo.key) {
      case LOGOUT_ITEM_KEY:
        signOut();
        break;
      case API_GEN_ITEM_KEY:
        delegate.onStartGenerateApiKey();
        break;
      case ADD_GITLAB_API_KEY:
        delegate.onStartAddGitlabApiKey();
        break;
      case USER_PREFERENCES_KEY:
        delegate.onOpenUserPreferences();
        break;
      case SUPPORT_MENU_ITEM_KEY:
        createSupportMail();
        break;
      case ABOUT_MENU_ITEM_KEY:
        setAboutDialogOpen(true);
        break;
      default:
        break;
    }
  };

  const menuItems: ItemType[] = useMemo(
    () => [
      {
        key: USERNAME_ITEM_KEY,
        icon: <UserOutlined />,
        label: `${user?.attributes?.name ?? 'No Name'}`,
        disabled: true,
      },
      {
        key: EMAIL_ITEM_KEY,
        icon: <MailOutlined />,
        label: `${user?.attributes?.email ?? 'No Email'}`,
        disabled: true,
      },
      model.workbenchAllowed
        ? {
            type: 'divider',
          }
        : null,
      model.workbenchAllowed
        ? {
            key: API_GEN_ITEM_KEY,
            icon: <KeyOutlined />,
            label: 'Generate API Key...',
          }
        : null,
      model.isMidasPeer
        ? {
            key: ADD_GITLAB_API_KEY,
            icon: <KeyOutlined />,
            label: 'GitLab API Key...',
          }
        : null,
      {
        type: 'divider',
      },
      model.workbenchAllowed
        ? {
            key: USER_PREFERENCES_KEY,
            icon: <SettingOutlined />,
            label: 'Preferences',
          }
        : null,
      model.workbenchAllowed
        ? {
            type: 'divider',
          }
        : null,
      {
        key: SUPPORT_MENU_ITEM_KEY,
        icon: <MailOutlined />,
        label: 'Create Support Email...',
      },
      {
        type: 'divider',
      },
      {
        key: ABOUT_MENU_ITEM_KEY,
        icon: <QuestionCircleOutlined />,
        label: 'About',
      },
      {
        type: 'divider',
      },
      {
        key: LOGOUT_ITEM_KEY,
        icon: <LogoutOutlined />,
        label: 'Log out',
      },
    ],
    [user?.attributes?.email, user?.attributes?.name, model.isMidasPeer, model.workbenchAllowed]
  );

  const unseenNotifications = notificationsModel.notifications.filter((n) => !n.isSeen).length;

  const notificationsPopover =
    unseenNotifications === 0 ? (
      <Button aria-label="Notifications" type="text" icon={<EmptyBellIconComponent />} title={'No Notifications'} />
    ) : (
      <Popover
        trigger="click"
        content={<NotificationsListControl notificationsModel={notificationsModel} notificationsDelegate={notificationsDelegate} />}
        onOpenChange={(open) => {
          if (!open) {
            void notificationsDelegate?.onSetNotificationsSeen();
          }
        }}
      >
        <Badge color="#e12f31" count={unseenNotifications > 99 ? '99+' : unseenNotifications}>
          <Button aria-label="Notifications" type="text" icon={<EmptyBellIconComponent />} />
        </Badge>
      </Popover>
    );

  return (
    <>
      <div className={`${styles.sidebarButtonsContainer} ${sidebarCollapsed ? styles.contractedContainer : styles.expandedContainer}`}>
        {!sidebarCollapsed && (
          <>
            <Button
              type="text"
              onClick={toggleTheme}
              icon={theme === 'light' ? <MoonIcon height={16} width={16} /> : <SunIcon height={18} width={18} />}
              aria-label="Toggle theme"
            />
            <Dropdown menu={{ items: menuItems, onClick: handleDropDownMenuItemClick }} placement="bottomLeft" trigger={['click']}>
              <Button type="text" icon={<UserIcon height={18} width={18} />} aria-label="Open user account dropdown" />
            </Dropdown>
            {notificationsPopover}
          </>
        )}
        {sidebarCollapsed && notificationsPopover}
        <Button
          type="text"
          onClick={handleSidebarCollapseToggle}
          icon={sidebarCollapsed ? <ArrowRightIcon height={18} width={18} /> : <ArrowLeftIcon height={18} width={18} />}
          aria-label={sidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
        />
      </div>
      <AboutDialog isOpen={aboutDialogOpen} onClose={handleAboutDialogClose} />
    </>
  );
}
