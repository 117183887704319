import { handleError } from '../../services/error-notification';
import { GlobalSearchModel, initialGlobalSearchResults, searchGlobal } from './models/global-search';
import { GlobalSearchViewDelegate } from './views/GlobalSearchView';
import { SearchApi } from './search-api';

export class GlobalSearchController implements GlobalSearchViewDelegate {
  private model = initialGlobalSearchResults(false);

  private searchApi = new SearchApi();

  private getModel = () => this.model;

  private update = (model: GlobalSearchModel) => {
    this.model = model;
    this.updateViewState(model);
  };

  constructor(private updateViewState: (_: GlobalSearchModel) => void) {}

  onSidebarCollapsedToggle(sidebarCollapsed: boolean): void {
    this.update({
      ...this.getModel(),
      sidebarCollapsed,
    });
  }

  closeModal(): void {
    this.update({
      ...this.model,
      modalOpen: false,
      selectedModalTab: window.CONFIG.searchProviders[0], //maybe not?
    });
  }

  async onGlobalSearch(): Promise<void> {
    handleError(await searchGlobal(this.searchApi, this.getModel, this.update));
  }

  onSearchTermChange(term: string) {
    this.update({ ...this.model, inputValue: term });
  }

  clearSearchResults() {
    this.update({ ...this.model, ...initialGlobalSearchResults(this.model.sidebarCollapsed) });
  }

  onSelectTab(selectedModalTab: string) {
    this.update({ ...this.model, selectedModalTab });
  }
}
