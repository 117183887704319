import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { axiosOptions } from './requests';

function getBaseAuthPath(): string {
  return `/api/authorization${window.CONFIG.isMidasPeer ? '/midas' : ''}`;
}

function getRedirectAuthUrl(): string {
  return `${window.location.hostname === 'localhost' ? 'http://localhost:3001' : ''}${getBaseAuthPath()}`;
}

export interface PluggableAuth {
  getAccessToken: () => Promise<string>;
  getUserSubject: () => Promise<string>;
}

let pluggable: PluggableAuth | undefined;

export function setPluggableAuth(auth: PluggableAuth) {
  pluggable = auth;
}

export function getAccessTokenFromCookie(): string | undefined {
  for (const cookie of document.cookie.split(';')) {
    const [name, value] = cookie.split('=').map((v) => v.trim());

    if (name === window.CONFIG.mstZoeAuthCookie) {
      return value;
    }
  }

  return undefined;
}

export async function getAccessToken(): Promise<string | undefined> {
  if (pluggable) {
    return await pluggable.getAccessToken();
  }

  return getAccessTokenFromCookie();
}

export async function getUserSubject(): Promise<string | undefined> {
  if (pluggable) {
    return await pluggable.getUserSubject();
  }

  return decodeAccessToken(getAccessTokenFromCookie())?.sub;
}

function decodeAccessToken(token: string | undefined): { sub: string; exp: number } | undefined {
  if (!token) {
    return undefined;
  }

  try {
    return jwtDecode(token);
  } catch (_) {
    return undefined;
  }
}

function willTokenExpireAfter(token: string | undefined, marginSeconds: number): boolean {
  const decoded = decodeAccessToken(token);

  if (!decoded) {
    return true;
  }

  return Date.now() + marginSeconds * 1000 >= decoded.exp * 1000;
}

export interface SignedInUser {
  attributes: {
    name: string;
    email: string;
  };
}

export function signOut() {
  window.location.href = `${getRedirectAuthUrl()}/logout`;
}

function login() {
  window.location.href = `${getRedirectAuthUrl()}/login?callback=${encodeURIComponent(location.href)}`;
}

export function handleLoginFlow(): SignedInUser | undefined {
  const accessToken = getAccessTokenFromCookie();
  if (!accessToken || willTokenExpireAfter(accessToken, -10)) {
    login();
    return undefined;
  }

  let refreshing = false;
  setInterval(async () => {
    if (refreshing) {
      return;
    }

    // Token is expired, login again
    if (willTokenExpireAfter(getAccessTokenFromCookie(), 0)) {
      login();
      return;
    }

    // Token is next to expiration, refresh it
    if (willTokenExpireAfter(getAccessTokenFromCookie(), 2 * 60)) {
      try {
        refreshing = true;
        // ignore call results
        await axios.post(`${getBaseAuthPath()}/refresh`, {}, { ...(await axiosOptions()), timeout: 10 * 1000 });
      } finally {
        refreshing = false;
      }
    }
  }, 1000);

  const attrs: { name: string; email: string } = jwtDecode(accessToken);

  return {
    attributes: {
      name: attrs.name,
      email: attrs.email,
    },
  };
}
