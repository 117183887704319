import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { Input, Modal, Space, Tabs, TabsProps, Typography } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';

import { titleCase } from '../../../services/formatting';

import { GlobalSearchModel } from '../models/global-search';

import { GlobalSearchViewDelegate } from './GlobalSearchView';
import { getTitle, GlobalSearchResult } from './GlobalSearchResult';

import styles from './GlobalSearchResults.module.css';

export function GlobalSearchResults({ model, delegate }: { model: GlobalSearchModel; delegate: GlobalSearchViewDelegate }) {
  const navigate = useNavigate();

  const { status, modalOpen, searchResults, inputValue, selectedModalTab } = model;

  const handleSearchButton = useCallback(() => {
    void delegate.onGlobalSearch();
  }, [delegate]);

  const handleCancel = useCallback(() => {
    delegate.closeModal();
  }, [delegate]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      void delegate.onGlobalSearch();
    },
    [delegate]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      delegate.onSearchTermChange(e.target.value);
    },
    [delegate]
  );

  const handleClickItem = useCallback(
    (key: string) => {
      delegate.closeModal();

      const url = key.substring(key.indexOf('|') + 1);
      const external = url.startsWith('http');
      if (!external) {
        navigate(url);
        return;
      }
      window.location.href = url;
    },
    [navigate, delegate]
  );

  const handleTabChange = useCallback(
    (tabId: string) => {
      delegate.onSelectTab(tabId);
    },
    [delegate]
  );

  if (!modalOpen) {
    return null;
  }

  const entries = Object.entries(searchResults);
  const tabPanes: TabsProps['items'] = entries.map(([key, value]) => ({
    key,
    label: titleCase(getTitle(key)) + (value.status === 'loaded' ? ' (' + value.searchResults.length + ')' : ''),
    icon: value.status === 'loading' ? <LoadingOutlined /> : <SearchOutlined />,
    children: <GlobalSearchResult key={key} result={value} onClickItem={handleClickItem} />,
  }));

  return (
    <Modal
      cancelText={'Close'}
      okButtonProps={{ style: { display: 'none' } }}
      open
      onCancel={handleCancel}
      onOk={handleCancel}
      maskClosable={false}
      width={'75%'}
    >
      <Space direction={'vertical'} className={'w-full'}>
        <div className={styles.inputContainer}>
          <label>Search:</label>
          &nbsp;&nbsp;&nbsp;
          <Input
            className={styles.inputTextbox}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={'Search...'}
            disabled={status === 'loading'}
            addonAfter={<SearchOutlined onClick={handleSearchButton} />}
          />
        </div>
        <div className={`${styles.cardContainer} w-full`}>
          <Tabs
            tabBarExtraContent={{
              left: <Typography.Title level={5}>Search Results</Typography.Title>,
            }}
            tabPosition={'left'}
            className={'w-full'}
            type={'line'}
            items={tabPanes}
            onChange={handleTabChange}
            activeKey={selectedModalTab}
            centered
          />
        </div>
      </Space>
    </Modal>
  );
}
