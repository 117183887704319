import React, { useCallback, useContext } from 'react';

import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { AppThemeContext } from '../../../hooks/use-theme';

import { GlobalSearchModel } from '../models/global-search';

import { GlobalSearchResults } from './GlobalSearchResults';

import styles from './GlobalSearchView.module.css';

export interface GlobalSearchViewDelegate {
  onGlobalSearch(): Promise<void>;

  onSearchTermChange(term: string): void;

  clearSearchResults(): void;

  closeModal(): void;

  onSelectTab(tabId: string): void;

  onSidebarCollapsedToggle(sidebarCollapsed: boolean): void;
}

export function GlobalSearchView({ model, delegate }: { model: GlobalSearchModel; delegate: GlobalSearchViewDelegate }) {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      delegate.onSearchTermChange(e.target.value);
    },
    [delegate]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      void delegate.onGlobalSearch();
    },
    [delegate]
  );

  const handleOpenButton = useCallback(() => {
    void delegate.onGlobalSearch();
  }, [delegate]);

  const handleClearButton = useCallback(() => {
    void delegate.clearSearchResults();
  }, [delegate]);

  const { theme } = useContext(AppThemeContext);
  const style = {
    borderColor: theme === 'light' ? '#595959' : 'white', // `1px solid ${theme === 'light' ? '#595959' : 'white'}`, // was rgb(171,173,178)
    backgroundColor: theme === 'light' ? 'white' : 'var(--gray-12)',
  };

  const disabled = model.status === 'loading';
  const content = model.sidebarCollapsed ? (
    <div className={`${styles.collapsedSearchContainer} w-full`}>
      <Button onClick={handleOpenButton} disabled={disabled} type={'text'} icon={<SearchOutlined />} />
    </div>
  ) : (
    <Space.Compact className={`${styles.searchContainer} w-full`}>
      <Input
        maxLength={40}
        className={styles.globalSearch}
        value={model.inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={'Search...'}
        aria-label="Search in the website"
        id="globalSearch"
        style={style}
        disabled={disabled}
      />
      <Button onClick={handleOpenButton} style={style} disabled={disabled} icon={<SearchOutlined />} />
    </Space.Compact>
  );

  return (
    <>
      {content}
      <GlobalSearchResults delegate={delegate} model={model} />
    </>
  );
}
