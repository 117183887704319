import React, { useCallback } from 'react';

import { Menu, MenuProps } from 'antd';
/* do not change this - it has to be /es/ */
import { SiderContext } from 'antd/es/layout/Sider';

import { getInstanceUrl } from '../../shared/instance-mapping';

import { ProviderSearchResults } from '../models/global-search';

import { SearchResult } from '../search-api';
import {
  AppstoreOutlined,
  BookOutlined,
  CloudServerOutlined,
  DashboardOutlined,
  DesktopOutlined,
  FolderOpenOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';

type MenuItem = Required<MenuProps>['items'][number];

/* antd being too clever for itself - assumes anything created under a Sider will depend on the Sider's context */
const fakeSiderProps = {
  siderCollapsed: false,
};

export function getIcon(type: string) {
  switch (type) {
    case 'instances':
      return <DesktopOutlined />;
    case 'resources':
      return <CloudServerOutlined />;
    case 'fx':
      return <FolderOpenOutlined />;
    case 'dashboards':
      return <DashboardOutlined />;
    case 'applications':
      return <AppstoreOutlined />;
    case 'tasks':
      return <ShoppingOutlined />;
    case 'documentation':
      return <BookOutlined />;
    default:
      return null;
  }
}

export function getTitle(type: string) {
  switch (type) {
    case 'fx':
      return 'File Browser';
    default:
      return type;
  }
}

export function GlobalSearchResult({ result, onClickItem }: { result: ProviderSearchResults; onClickItem: (key: string) => void }) {
  function renderItem(type: string, item: SearchResult): MenuItem {
    let href: string;

    switch (type) {
      case 'instances':
        href = getInstanceUrl(item.id);
        break;
      case 'resources':
        href = `/resources#resource-${item.id}`;
        break;
      case 'dashboards':
        href = `/dashboard/${item.id}`;
        break;
      case 'tasks':
        href = `/jobs/${item.id}`;
        break;
      case 'applications':
        href = `/applications/${item.id}`;
        break;
      case 'fx':
        href = item.id;
        break;
      default:
        href = '';
        break;
    }

    // Add a `label` if you want the appearance to be something other than the 'value'
    return {
      key: `${type}|${href}`,
      icon: getIcon(type),
      label: item.title,
    } as unknown as MenuItem;
  }

  const menuItems: MenuItem[] = (result.searchResults || []).map((searchResult) => {
    return renderItem(result.label, searchResult);
  });

  const handleItemClick = useCallback(
    ({ key }: { key: string }) => {
      onClickItem?.(key);
    },
    [onClickItem]
  );

  return (
    <div className={'result-container'}>
      <SiderContext.Provider value={fakeSiderProps}>
        <Menu onClick={handleItemClick} disabled={result.status !== 'loaded'} mode={'vertical'} items={menuItems} />
      </SiderContext.Provider>
    </div>
  );
}
