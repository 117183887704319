import React, { useCallback, useMemo } from 'react';
import { Button, Modal, Typography } from 'antd';

import localVersionInfo from '../../../version-info.json';

import styles from './AboutDialog.module.css';

const { Title } = Typography;

const countries = [
  ['🇺🇸', 'United States'],
  ['🇬🇧', 'United Kingdom'],
  ['🇦🇺', 'Australia'], // matt
  ['🇨🇦', 'Canada'], // lexi
  ['🇬🇷', 'Greece'],
  ['🇮🇹', 'Italy'],
  ['🇵🇱', 'Poland'], // kris, dawid, rad
  ['🇵🇹', 'Portugal'],
  ['🇷🇴', 'Romania'],
];

export type VersionInfo = {
  version: string | undefined;
  branch: string | undefined;
  pipeline: string | undefined;
  namespace: string | undefined;
};

export function getContent(versionInfo: VersionInfo) {
  const exploded = Object.entries(versionInfo);

  return exploded.map(([key, value], index) => (
    <>
      <span key={key}>
        {key.toLowerCase()}: {(value || 'Unknown').toString()}
      </span>
      &nbsp;{index < exploded.length - 1 && '|'}&nbsp;
    </>
  ));
}

export function AboutDialog({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const {
    supportEmailAddress,
    branding: { longName, shortName },
    isMidasPeer,
  } = window.CONFIG;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const countryList = useMemo(() => {
    return countries.map(([key, value]) => (
      <span key={key} title={value}>
        {key}&nbsp;
      </span>
    ));
  }, []);

  const description = isMidasPeer ? (
    <>{shortName} allows you to analyze and visualize petabytes of market data in a web-based containerized environment.</>
  ) : (
    <>{shortName} allows you to analyse and visualise petabytes of market data in a web-based containerised environment.</>
  );

  return isOpen ? (
    <Modal
      maskClosable={false}
      open
      onCancel={handleClose}
      onOk={handleClose}
      okText="OK"
      cancelButtonProps={{ style: { display: 'none' } }}
      destroyOnClose
      footer={
        <div className={'flex'}>
          <div className={styles.countryList}>{countryList}</div>
          <Button type={'primary'} onClick={handleClose}>
            Close
          </Button>
        </div>
      }
    >
      <div>
        <Title level={3}>{longName}</Title>
        <p className={styles.topPadded}>{description} </p>
        <p className={styles.topPadded}>&copy; {new Date().getFullYear()} LSEG PLC. All rights reserved.</p>
        <p className={styles.topPadded}>
          <a
            href="https://www.lseg.com/en/data-analytics/market-data/data-feeds/tick-history/tick-history-workbench"
            target="_blank"
            rel="noreferrer"
          >
            Product Homepage
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="https://www.lseg.com/en/policies" target="_blank" rel="noreferrer">
            LSEG Usage Policies
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href={`mailto:${supportEmailAddress}?subject=${shortName}%20Support%20Question`}>Support Email Address</a>
        </p>
        <p className={styles.smallText}>
          <strong>server:&nbsp;</strong>
          {getContent(window.CONFIG.versionInfo)}
        </p>
        <p className={styles.smallText}>
          <strong>client:&nbsp;</strong>
          {getContent(localVersionInfo)}
        </p>
      </div>
    </Modal>
  ) : null;
}
